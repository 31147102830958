.router-link {
    @apply flex;
    @apply items-center;
    @apply px-4;
    @apply py-3;
    @apply transition;
    @apply ease-in-out;
    @apply duration-150;
    @apply font-medium;

    &:focus {
        @apply outline-none bg-gray-700;
    }

    &.router-link-desktop {
        @apply text-sm leading-5;
    }

    &.router-link-mobile {
        @apply text-base leading-6;
    }

    &.router-link-active,
    &.router-link-exact-active {
        @apply text-white bg-gray-900;
    }

    &:not(.router-link-active),
    &:not(.router-link-exact-active), {
        @apply text-gray-300;

        &:hover {
            @apply text-white bg-gray-700;
        }

        &:focus {
            @apply text-white bg-gray-700;
        }
    }
}

.btn {
    @apply px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md;

    &:focus {
        @apply outline-none;
    }

    &:disabled {
        @apply opacity-50 cursor-not-allowed;
    }

    &.btn-white {
        @apply border-gray-300 bg-white text-gray-700;

        &:not(:disabled) {
            &:hover {
                @apply text-gray-500;
            }

            &:focus {
                @apply shadow-outline-blue border-blue-300;
            }
        }
    }

    &.btn-blue {
        @apply bg-blue-600 text-white;

        &:not(:disabled) {
            &:hover {
                @apply bg-blue-500;
            }

            &:active {
                @apply bg-blue-700;
            }

            &:focus {
                @apply shadow-outline-blue border-blue-700;
            }
        }
    }

    &.btn-green {
        @apply bg-green-600 text-white;

        &:not(:disabled) {
            &:hover {
                @apply bg-green-500;
            }

            &:active {
                @apply bg-green-700;
            }

            &:focus {
                @apply shadow-outline-blue border-green-700;
            }
        }
    }

    &.btn-red {
        @apply bg-red-600 text-white;

        &:not(:disabled) {
            &:hover {
                @apply bg-red-500;
            }

            &:active {
                @apply bg-red-700;
            }

            &:focus {
                @apply shadow-outline-blue border-red-700;
            }
        }
    }

    &.btn-secondary {
        @apply bg-gray-600 text-white;

        &:not(:disabled) {
            &:hover {
                @apply bg-gray-500;
            }

            &:active {
                @apply bg-gray-700;
            }

            &:focus {
                @apply shadow-outline-blue border-gray-700;
            }
        }
    }
}

.pagination-link {
    @apply relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white transition duration-150 ease-in-out;

    &:not(:disabled) {
        &:hover {
            @apply text-gray-500;
        }

        &:focus {
            @apply outline-none shadow-outline-blue border-blue-300;
        }

        &:active {
            @apply bg-gray-100 text-gray-700;
        }
    }
}

.velmld-overlay {
    z-index: 35 !important;
}
