.grecaptcha-badge {
    visibility: hidden !important;
}

.tox-tinymce, .tox-toolbar__group {
    border-color: #cbd5e0 !important;
}

.tox-toolbar__primary {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg height='39px' viewBox='0 0 40 39px' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='38px' width='100' height='1' fill='%23cbd5e0'/%3E%3C/svg%3E") left 0 top 0 #fff !important;
}
